import stickybits from 'stickybits';

document.addEventListener('DOMContentLoaded', () => {
    const el = document.querySelectorAll('.js-sticky');

    const init = () => {
        let offset = 100,
            topbar = document.querySelector('.js-topbar');
        
        topbar ? offset = topbar.clientHeight : false;
        
        for (let i = 0; i < el.length; i ++) {
            let stickybit = stickybits(el[i], {
	            stickyBitStickyOffset: offset
            });
        }
    };
    window.addEventListener('load', () => {
        el.length > 0 ? init() : false;
    })
}, false);
