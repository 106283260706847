import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

document.addEventListener('DOMContentLoaded', () => {    
    window.runScroll = (el, o) => {
        let offsetTop = document.querySelector(el).offsetTop,
            topbar_height = 0, yOffset = 0;
            
        if (document.querySelector('.js-topbar')) {
           topbar_height = document.querySelector('.js-topbar').clientHeight;
           yOffset = -topbar_height; 
        }

        o === undefined ? o = 0 : false;
        
        const y = document.querySelector(el).getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});

/*
        scroll({
            top: offsetTop - topbar_height - o,
            behavior: "smooth"
        })
*/
    };

    const link = document.querySelectorAll('a');
    link.forEach(element => {
        if (element.tagName.toLowerCase() === 'a' && element.getAttribute('href').includes('#')) {
            element.addEventListener('click', function(e) {
                e.preventDefault() ? e.preventDefault() : e.preventDefault = false;  
                let target = e.currentTarget.getAttribute('href');
                window.runScroll(target);
            });
        }
    });

/*
    const gtt = document.querySelectorAll("[data-target]");


    

    if (gtt.length > 0) {
        const action = (e) => {
        	e.preventDefault() ? e.preventDefault() : e.preventDefault = false;  
            let target = e.currentTarget.dataset.target,
                offset = e.currentTarget.dataset.offset;
                
            var p = document.querySelector(target);
            var style = getComputedStyle(p);
            
            console.log(style.paddingTop);
            
            //display("Current marginTop: " + style.marginTop);

           window.runScroll(target, offset);
        };

        for (let i = 0; i < gtt.length; i++) {
            gtt[i].addEventListener('click', action);
        }
    }
*/
}, false);
